import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./views/Home";
import CartProvider from "./context/cart";
import 'react-multi-carousel/lib/styles.css';
function App() {
  return (
    <CartProvider>
     <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
    </Routes>
    </BrowserRouter>
    </CartProvider>
   
  );
}

export default App;
