import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CartStateContext } from '../context/cart';
import { FiShoppingBag,FiUser,FiHeart,FiHome } from "react-icons/fi";

const Styled = styled.div`

/* var  */
a.nav__link.active-link {
   // color: ${props => props.desing};
}


position: fixed;
bottom: 0;
z-index: 999999;
background-color: #fff;
width: 100%;
div#nav-menu {
    width: 100%;
}
ul.nav__list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: -webkit-fill-available !important;
    margin: auto;
}
header#header {
    padding: 10px 0;
}
a.nav__link.active-link {
    color: ${props => props.desing.settings.Main_color_app};
}
ul.nav__list {
    list-style: none !important;
}
a.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5a5a5a;
}
span.nav__name {
    font-size: 10px;
}
i.nav__icon svg {
    width: 24px;
    height: 24px;
}
`;

const Header = () => {
  const {desing} = useContext(CartStateContext);
  const [active,setactive] = useState(1);
  return (
    <Styled  desing={desing}>
    <header className="header" id="header">
            <nav className="nav container">
                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">
                        <li className="nav__item">
                            <a  onClick={(e)=>setactive(1)} className={"nav__link"+(active==1?" active-link":"")}>
                                <i className='nav__icon'>
                                    <FiHome/>
                                </i>
                                <span className="nav__name">Home</span>
                            </a>
                        </li>
                        
                        <li className="nav__item">
                        <a  onClick={(e)=>setactive(2)} className={"nav__link"+(active==2?" active-link":"")}>
                                <i className='nav__icon'>
                                <FiShoppingBag/>
                                </i>
                                <span className="nav__name">commandes</span>
                            </a>
                        </li>

                        <li className="nav__item">
                        <a  onClick={(e)=>setactive(3)} className={"nav__link"+(active==3?" active-link":"")}>
                                <i className='nav__icon'>
                                <FiHeart/>
                                </i>
                                <span className="nav__name">Favoris</span>
                            </a>
                        </li>

                        <li className="nav__item">
                        <a  onClick={(e)=>setactive(4)} className={"nav__link"+(active==4?" active-link":"")}>
                                <i className='nav__icon'>
                                <FiUser/>
                                </i>
                                <span className="nav__name">Profil</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </Styled>
  )
}

export default Header