import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import { CartStateContext } from '../context/cart';
const Styled  = styled.div`
background-color: #fff;
padding: 0 10px;
h3{
    font-size: 15px;
    font-weight: 800;
}
p{
    font-size: 12px;
    color: #00000099;
}
.section_titel {
    padding: 5px 0;
}
img.img {
    height: 60px;
    border-radius: ${props => props.desing.settings.margin_vertical}px;
}

li.react-multi-carousel-item {
    //width: 350px !important;
}
`;
const responsive = {
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 5,
      partialVisibilityGutter: 1// this is needed to tell the amount of px that should be visible.
    }
  }
const Servises = (props) => {
    const {desing} = useContext(CartStateContext);
useEffect(()=>{

},[]);
  return (
    <Styled desing={desing}>
        <div className='section_titel'>
            <h3>{props.data.title}</h3>
            <p>{props.data.subtitle}</p>
        </div>
        { props.data.content.length?<Carousel arrows={false} swipeable={true} responsive={responsive}>
        {
            props.data.content.map((x,i)=>
                <a key={i}><img className='img' src={x.urlimage}/></a>
            )
        }
        </Carousel>:<></>}
    </Styled>
  )
}

export default Servises