import React, { useReducer, createContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import json from '../jsonStyle.json';

const initialState = {
  isCartOpen: false,
  islogin:false,
  isModalOpen:false,
  isShowAlert:false,
  dataModal:{},
  items: [],
  courency:"€",
  user:{},
  wishlist:[],
  total: 0,
  weight: 0,
  desing:{},
};

export const CartStateContext = createContext();
export const CartDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {


    case "TOGGLE_CART_POPUP":
      return {
        ...state,
        isCartOpen: !state.isCartOpen
      };



    case "TOGGLE_CART_ALERT":
        return {
          ...state,
          isShowAlert: false
    };



    case "TOGGLE_LOGIN":
        return {
          ...state,
          user: action.payload.user
    };


    case "TOGGLE_LOGIN_STATE":
        return {
          ...state,
          islogin: !state.islogin
    };
    

    case "TOGGLE_LOGOUT":
      return {
        ...state,
        user:{},
        islogin: false
    };

      case "TOGGLE_MODAL_POPUP":
      return {
        ...state,
        isModalOpen: !state.isModalOpen
      };

      case "TOGGLE_MODAL_DATA":
        let DataModal_d = action.payload.DataModal;

        return {
          ...state,
          dataModal: DataModal_d
      };
      

    case "ADD_TO_CART":
      const id = action.payload.cartItem.id;
      const isOld = state.items.map((item) => item.id).includes(id);
      let cartItems = null;
      if (isOld) {
        const items = state.items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity: item.quantity + 1
            };
          }
          return item;
        });
        cartItems = [...items];
      } else {
        cartItems = [...state.items, action.payload.cartItem];
      }

      return {
        ...state,
        items: cartItems,
        isShowAlert : true
      };

      case "ADD_TO_WISHLIST":
        const id_wishlist = action.payload.wishlist.id;
        const isOld_wishlist = state.wishlist.map((item) => item.id).includes(id_wishlist);
        let wishlist_list = null;
        if (isOld_wishlist) {
          const wishlist = state.wishlist.map((item) => {
            if (item.id === id_wishlist) {
              return {
                ...item
              };
            }
            return item;
          });
          wishlist_list = [...wishlist];
        } else {
          wishlist_list = [...state.wishlist, action.payload.wishlist];
        }
  
        return {
          ...state,
          wishlist: wishlist_list
        };

    



      case "REMOVE_FROM_CART_AD":
        const idd = action.payload.cartItem.id;
        const isOldd = state.items.map((item) => item.id).includes(idd);
        let cartItemsd = null;
        if (isOldd) {
          const items = state.items.map((item) => {
            if (item.id === idd && item.quantity!==1) {
                return {
                    ...item,
                    quantity: item.quantity - 1
                  };
            }
            return item;
          });
          cartItemsd = [...items];
        } else {
            cartItemsd = [...state.items, action.payload.cartItem];
        }
  
        return {
          ...state,
          items: cartItemsd,
      
        };


      case "ADD_TO_CART_ADV":
        const id_ = action.payload.cartItem.id;
        const isOld_ = state.items.map((item) => item.id).includes(id_);
        let cartItem = null;
        if (isOld_) {
          const items = state.items.map((item) => {
            if (item.id === id_) {
              return {
                ...item,
                 quantity: action.payload.cartItem.quantity  
              };
            }
            return  {
                ...item,
                quantity:  item.quantity  
              };
          });
          cartItem = [...items];
        } else {
            cartItem = [...state.items, action.payload.cartItem];
        }
        return {
          ...state,
          items: cartItem,
         
        };



    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: state.items.filter(
          (item) => item.id !== action.payload.cartItemId
        )
      };


    case "REMOVE_FROM_WISHLIST":
        return {
          ...state,
          wishlist: state.wishlist.filter(
            (item) => item.id !== action.payload.wishlistId
          )
    };

    case "CLEAR_CART":
      return {
        ...state,
        ...initialState.items,
        ... initialState.total
      };

      case "CLEAR_FILLTER":
        return {
          ...state,
          ...initialState.fillter
        };

      case "TOTAL_CART":
        let total_cart = 0; 
        let weight_ = 0;
         state.items.map((item) =>{
            let price_coucent =  item.pric_promo!==""?item.pric_promo:item.price;
            total_cart = total_cart+ (parseInt(price_coucent) * item.quantity);
            weight_ = weight_ + (item.weight * item.quantity);
          
        });
        return {
          ...state,
          total: total_cart,
          weight : weight_
    
      };

    case "DATA_STYLE":
      let DataD= action.payload.DataD;
      return {
        ...state,
        desing: DataD
      }; 

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const toggleCartPopup = (dispatch) => {
  return dispatch({
    type: "TOGGLE_CART_POPUP"
  });
};

export const toggleModalPopup = (dispatch) => {
  return dispatch({
    type: "TOGGLE_MODAL_POPUP"
  });
};


export const addToModal = (dispatch, dataModal) => {
  return dispatch({
    type: "TOGGLE_MODAL_DATA",
    payload: {
      DataModal: dataModal
    }
  });
};

export const addToCart = (dispatch, cartItem) => {
  return dispatch({
    type: "ADD_TO_CART",
    payload: {
      cartItem: cartItem
    }
  }),dispatch({type: "TOTAL_CART"});
};


export const addToWishList = (dispatch, wishlist) => {
  return dispatch({
    type: "ADD_TO_WISHLIST",
    payload: {
      wishlist: wishlist
    }
  });
};

export const removFromWishlist = (dispatch,wishlistId) =>{
  return dispatch({
     type: "REMOVE_FROM_WISHLIST",
     payload: {
      wishlistId: wishlistId
     }
   });
 };
 




// REMOVE FILLTER
export const clearFillter = (dispatch) => {
    return dispatch({
      type: "CLEAR_FILLTER"
    });
  };



export const removFromCartAd = (dispatch,cartItem) =>{
 return dispatch({
    type: "REMOVE_FROM_CART_AD",
    payload: {
      cartItem: cartItem
    }
  }),dispatch({type: "TOTAL_CART"});
};

export const addToCartAdv = (dispatch, cartItem) => {
    return dispatch({
      type: "ADD_TO_CART_ADV",
      payload: {
        cartItem: cartItem
      }
    }),dispatch({type: "TOTAL_CART"});
};

export const  addStyle = (dispatch,DataD)=>{
  return dispatch({
    type: "DATA_STYLE",
    payload: {
      DataD: DataD
    }
  })
};



// 
export const addToCartAdvId = (dispatch, cartItem) => {
    return dispatch({
        type: "ADD_TO_CART_ADV_ID",
        payload: {
            cartItem: cartItem
        }
      }),dispatch({type: "TOTAL_CART"});
}

export const removeFromCart = (dispatch, cartItemId) => {
  return dispatch({
    type: "REMOVE_FROM_CART",
    payload: {
      cartItemId: cartItemId
    }
  }),dispatch({type: "TOTAL_CART"});
  
};

export const clearCart = (dispatch) => {
  return dispatch({
    type: "CLEAR_CART"
  });
};

export const loginUser=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: user
    }
  }),dispatch({type: "TOGGLE_LOGIN_STATE"});
}


export const AlertCart = (dispatch) => {
  return dispatch({
    type: "TOGGLE_CART_ALERT"
  });
};


export const logout=(dispatch) =>{
  return dispatch({
    type: "TOGGLE_LOGOUT"
  });
}


const CartProvider = ({ children }) => {
  const [persistedCartItems, setPersistedCartItems] = useLocalStorage("cartItems",[]);
  const [wishlistList, setwishlistList] = useLocalStorage("wishlist",[]);
  const [courency, setcourency] = useLocalStorage("courency","€");
  const [totalCartItems, settotalCartItems] = useLocalStorage("total",0);
  const [weight, setweight] = useLocalStorage("weight",0);
  const [userdata, setuserdata] = useLocalStorage("user",{});
  const [desings, setdesing] = useLocalStorage("desing",{});
  const [islogin, setislogin] = useLocalStorage("islogin",false);
  const persistedCartState = {
    isCartOpen: false,
    isModalOpen:false,
    isShowAlert:false,
    islogin:islogin,
    user: userdata,
    courency:courency,
    dataModal:{},
    items: persistedCartItems || [],
    wishlist: wishlistList || [],
    total : totalCartItems,
    weight : weight,
    desing : desings||{}
  };

  const [state, dispatch] = useReducer(reducer, persistedCartState);
  useEffect(() => {
    setdesing(state.desing);
    setPersistedCartItems(state.items);
    settotalCartItems(state.total);
    setweight(state.weight);
    setuserdata(state.user);
    setislogin(state.islogin);
    setcourency(state.courency);
    setwishlistList(state.wishlist);
  }, [JSON.stringify(state.items),state.user,state.islogin,JSON.stringify(state.wishlist),state.items,state.desing]);
  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

export default CartProvider;