import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CartStateContext } from '../context/cart';


const Styled = styled.div`
padding: 0 10px;
background-color: #fff;
margin-top: 15px;
.icons{
    padding: 0px 10px 0px 5px;
}

h3{
    font-size: 15px;
    font-weight: 800;
}
p{
    font-size: 12px;
    color: #00000099;
}
.section_titel {
    padding: 0;
}

.ddg.col-4 {
    padding: 6px;
}
.active{
    display: block;
}
.desactive{
    display: none;
}
overflow: hidden !important;
`;

const DivS = styled.div`

    position: relative;
    width: -webkit-fill-available;
    margin: 0;
    background-color: ${props => props.color};
    background-image: linear-gradient(70deg,${props => props.color} 20%, #ffffff87);
    border-radius: ${props => props.desing.settings.margin_vertical}px;

span {
    position: absolute;
    z-index: 2;
    left: 10px;
    top: 5px;
    color: #fff;
    font-size: 12px;
}
img{
    position: relative;
    z-index: 1;
    width: -webkit-fill-available;
    border-radius: ${props => props.desing.settings.margin_vertical}px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

`;

const CartServices = (props) => {
const {desing} = useContext(CartStateContext);
const [ve,setve] = useState("desactive");
const changecss =(e,i)=>{
    if(i==7){
        if(ve=="desactive"){
            setve("active");
        }else{
            setve("desactive");
        }
        
    }

}
useEffect(()=>{
    
},[]);
  return (
    <Styled desing={desing}>
        <div className='section_titel'>
            <h3>{props.data.title}</h3>
            <p>{props.data.subtitle}</p>
        </div>
        <Row className='icons'>
            {
              props.data.content.map((x,i)=><Col className={'ddg '+(i<5?"active":i!=7?ve:"active")} key={i} xs={4}> <DivS onClick={(e)=>changecss(e,i)} desing={desing} color = {x.main_color_button} ><span>{x.title}</span><img src={x.icon}/></DivS></Col>) 
            }
        </Row>
    </Styled>
  )
}

export default CartServices