import React, { useContext } from 'react';
import styled from 'styled-components';
import { CartStateContext } from '../context/cart';



const Styled = styled.div`
    margin: 0 10px;
    padding: 1px 10px;

    background-color: #fff;
    border-radius: ${props => props.desing.settings.margin_vertical}px;
    box-shadow: rgb(0 0 0 / 13%) 0px 3px 8px;
.second {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: solid;
    border-width: 1px;
    color: #000;
    border-color: #d7d7d7;
    padding-top: 10px;
}
img {
    max-width: 25px;
}
.last {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0;
}
.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
h3,span{
    font-size: 15px;
}
.icon span {
    font-size: 10px;
}
`;

const Balance = () => {
const {desing} = useContext(CartStateContext);
  return (
    <Styled desing={desing}>
        <div className='second'>
            <h3>Balance</h3>
            <span>45 Pts</span>

        </div>
        <div className='last'>
            <div className='icon'>
                <img src='https://next.codany.net/wallet.png'/>
                <span>wallet</span>
            </div>
            <div className='icon'>
                <img src='https://next.codany.net/wallet.png'/>
                <span>Recharge</span>
            </div>
            <div className='icon'>
                <img src='https://next.codany.net/wallet.png'/>
                <span>Demande</span>
            </div>
            <div className='icon'>
                <img src='https://next.codany.net/wallet.png'/>
                <span>Code Promo</span>
            </div>

        </div>

    </Styled>
  )
}

export default Balance