import React, { useContext, useEffect, useState }  from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Header from '../com/Header';
import Balance from '../com/Balance';
import ReactWeather from 'react-open-weather';
import {addStyle, CartDispatchContext, 
  CartStateContext
} from "../context/cart";
import CartServices from '../com/CartServices';
import Promo from '../com/Promo';
import Servises from '../com/Servises';

//Optional include of the default css styles
//${props => props.inputColor || "palevioletred"}

const StyledDIV = styled.div`
.bannerimage img {
    width: 100%;
}
.bannerimage {
    max-height: 270px;
    position: fixed;
    top: 0;
}
.mainSection {
    position: relative;
    padding-bottom: 70px;
}
.ifo_user {
    padding: 60px 10px;
    color: #fff;
}
.ifo_user h3{
  font-size: 20px;
  font-weight: 400;
  padding-top: 10px;
}
.ifo_user span {
    font-weight: 800;
}
.ifo_user p {
    font-size: 14px;
}
`;
const Home = () => {
  /* respansev */
 const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
 const {desing} = useContext(CartStateContext);
 const dispatch = useContext(CartDispatchContext);
const [ready,setready] = useState(false);
  const getestyleapi = async() =>{
    const datas = await fetch('https://super.xdminamir.com/api/getstyle.php');
    const data = await datas.json();
    await addStyle(dispatch,data );
    setready(true);
  }

  useEffect(()=>{
    getestyleapi();
  },[]);
  return(
    desing!=null?<StyledDIV>
      {
        isTabletOrMobile&&ready?<>
        <div className='bannerimage'>
          <img className='image' src={desing.settings.header_background}/>
        </div>

        <div className='mainSection'>
          
          {/* FIRST section */}
          <div className='ifo_user'>
            <h3>Bonjour,<span> raid</span></h3>
            <p>Que voulez-vous faire aujourd'hui ?</p>

          </div>

          <ReactWeather
          forecast="today"
          apikey="015b8695c096695b64f372fee541651a"
          type="geo"
          lat="48.1351"
          lon="11.5820"
          />
          <Balance/>
          {
          desing.sections.map((x,i)=>{
            if(x.type=="MainButtons"){
              return <CartServices key={i} data = {x}/>;
            }
            if(x.type=="List"){
              return <Promo key={i} data = {x}/>;
            }
            if(x.type=="ListServices"){
              return <Servises key={i} data = {x}/>;
            }

          })
        }
        </div>



        <Header/>
        </>:<></>
      }
      
    </StyledDIV>:<></>
  )
};

export default Home;
