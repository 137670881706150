
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

:root {
  --main_color: #ff0c0c;
  --main_color2:#ff0c0cb3;
  --black_color:#262626;
  --gray_color: #a7a7a7;
  --gray_color2: #a7a7a745;
  --white: #ffffff;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

  body,html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    height: -webkit-fill-available;
    height: -moz-available;
    background-color: #fafafa;
  }
 a,h1,span,h2,h3,h4,p {
  font-family: 'Roboto', sans-serif;
  margin:0;
  padding: 0;
 }
 a{
  text-decoration: none;
  cursor: pointer;
 }
 body{
   background-color: #fff !important;
 }

 /* loader */
 div#loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    background-color: #fff;
}
 .loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #FFB800;
  font-size: 10px;
  margin: 100% auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


`;

export default GlobalStyle;